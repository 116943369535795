import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ElementUI from 'element-ui';
import { MessageBox } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@wangeditor/editor/dist/css/style.css';
import './utils/rem'

Vue.use(ElementUI);
Vue.use(VueAxios, axios)
Vue.prototype.$axios = axios
Vue.config.productionTip = false
// request 拦截器实现
axios.interceptors.request.use(
  (config) => {
    // config.baseURL = 'http://192.168.110.251:8009'
    // config.baseURL = 'http://211.149.137.28:8005'
    config.baseURL = 'https://www.wedoco.tech/blapis'
    config.withCredentials = true
    config.timeout = 30000000
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
// 在 response 拦截器实现
axios.interceptors.response.use(
  (response) => {
    if (response.data != '') {
      if (response.data.code == 200) {
        let data
        const headers = response.headers
        if (response.data == undefined) {
          data = JSON.parse(response.request.responseText)
        } else if (headers['content-type'] === 'application/octet-stream;') {
          data = response.data
        } else {
          data = response.data
        }
        return data
      } else if (response.data.code == 401) {
        MessageBox.alert('登录超时,请重新登录!', '登录超时', {
          confirmButtonText: '跳转登录页面',
          callback: () => {
            window.location.href = process.env.NODE_ENV === "prod" ? "/" : "/"
          },
        })
      } else {
        return response.data
      }
    }
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          alert('请求错误')
          break
        case 401:
          MessageBox.alert('登录超时,请重新登录!', '登录超时', {
            confirmButtonText: '跳转登录页面',
            callback: () => {
              sessionStorage.clear()
              window.location.href = process.env.NODE_ENV === "prod" ? "/" : "/"
            },
          })
          break
        case 403:
          alert('拒绝访问')
          break
        case 404:
          alert('请求地址出错')
          break
        case 408:
          alert('请求超时')
          break
        case 500:
          alert('服务器内部错误')
          break
        case 501:
          alert('服务未实现')
          break
        case 502:
          alert('网关错误')
          break
        case 503:
          alert('服务不可用')
          break
        case 504:
          alert('网关超时')
          break
        case 505:
          alert('HTTP版本不受支持')
          break
        default:
      }
    }
    //屏蔽token失效时多余的错误提示
    // return err.response.status == 401
    //   ? { code: 200, data: [] }
    //   : err.response.data
  }
)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
