import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 登录页面
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  // 首页
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    children: [
      // 产品图片管理
      {
        path: '/productImg',
        name: 'productImg',
        component: () => import('../views/productImg/productImg.vue')
      },
      // 内容管理
      {
        path: '/contents',
        name: 'contents',
        component: () => import('../views/contents/contents.vue')
      },
      // 新增内容
      {
        path: '/addContent',
        name: 'addContent',
        component: () => import('../views/contents/addContent.vue')
      },
      // 关于我们
      {
        path: '/aboutUs',
        name: 'aboutUs',
        component: () => import('../views/info/aboutUs.vue')
      },
      // 经销商管理
      {
        path: '/dealer',
        name: 'dealer',
        component: () => import('../views/info/dealer.vue')
      },
      // 图片管理
      {
        path: '/img',
        name: 'img',
        component: () => import('../views/information/img.vue')
      },
      // 用户管理
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/user.vue')
      },
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
